<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M14.9312 20.1592C14.9312 19.5686 15.4099 19.0899 16.0005 19.0899H19.0899V16.0005C19.0899 15.4099 19.5687 14.9312 20.1592 14.9312H20.7931C21.3837 14.9312 21.8624 15.4099 21.8624 16.0005V19.0899H24.9518C25.5424 19.0899 26.0211 19.5686 26.0211 20.1592V20.7931C26.0211 21.3836 25.5424 21.8624 24.9518 21.8624H21.8624V24.9518C21.8624 25.5424 21.3837 26.0211 20.7931 26.0211H20.1592C19.5687 26.0211 19.0899 25.5424 19.0899 24.9518V21.8624H16.0005C15.4099 21.8624 14.9312 21.3836 14.9312 20.7931V20.1592Z"
        fill="inherit"/>
    <path clip-rule="evenodd"
          d="M26.1534 31.5887C24.4504 32.4605 22.5207 32.9523 20.4762 32.9523C13.5858 32.9523 8 27.3665 8 20.4762C8 13.5858 13.5858 8 20.4762 8C27.3665 8 32.9523 13.5858 32.9523 20.4762C32.9523 22.5219 32.4599 24.4526 31.5872 26.1563L38.8725 33.4417C40.3729 34.942 40.3729 37.3745 38.8725 38.8748C37.3722 40.3751 34.9398 40.3751 33.4394 38.8748L26.1534 31.5887ZM30.8137 20.4762C30.8137 26.1854 26.1854 30.8137 20.4762 30.8137C14.7669 30.8137 10.1386 26.1854 10.1386 20.4762C10.1386 14.7669 14.7669 10.1386 20.4762 10.1386C26.1854 10.1386 30.8137 14.7669 30.8137 20.4762ZM28.0104 30.4213L34.9517 37.3625C35.6168 38.0277 36.6952 38.0277 37.3603 37.3625C38.0255 36.6974 38.0255 35.619 37.3603 34.9539L30.4194 28.013C29.7299 28.9212 28.9188 29.7321 28.0104 30.4213Z"
          fill="inherit"
          fill-rule="evenodd"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-zoom-in-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
